html, body, #root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.booksCarousel {
  /* background-color: #412a00;*/
  background: #000;
}
/* About Page */
.aboutContent p {
  color: #333;
  font-size: 1em;
  font-family: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
  margin-bottom: 1em;
}

/* Swiper CSS */
.swiper {
  width: 100%;
  height: 100%;
  padding: 1em !important;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2em 4em;
  padding-top: 0;
}

.rattan-bg {
  background-image: url('../src/assets/Rattan.jpg');
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-image: url('../src/assets/Rattan.jpg');
}

.background-tint {
  background-color: rgba(12, 11, 10, 0.55);
  background-blend-mode: multiply;
}

.swiper-slide .title {
  font-weight: bolder;
}

.swiper-slide .text {
  line-height: 1.3;
  flex-wrap: wrap;
}

.swiper-wrapper {
  padding-top: 2em;
}

.serif {
  font-family: serif;
}

.paper-bg {
  background: url('./assets/textured-bg.jpeg');
  background-size: cover;
}

.stf__item {
  box-shadow: inset 0 0 30px 0 rgba(36,10,3,.5), -2px 0 5px 2px rgba(0,0,0,.4);
}