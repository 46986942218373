@tailwind base;
@tailwind components;
@tailwind utilities;

::before, ::after {
  border: 0;
}

body {
  background: #f0efed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: "Maragsa", serif;
}


#nav li {
  @apply border-none font-kawit text-yellow-500 text-3xl;
}

#nav li:hover { 
  text-shadow: 0 0 30px #ffffff;
}

@font-face {
    font-family: 'Kawit';
    src: url('./assets/fonts/KawitFreeItalic.woff2') format('woff2'),
        url('./assets/fonts/KawitFreeItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kawit';
    src: url('./assets/fonts/KawitFreeExtItalic.woff2') format('woff2'),
        url('./assets/fonts/KawitFreeExtItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kawit';
    src: url('./assets/fonts/KawitFreeCndItalic.woff2') format('woff2'),
        url('./assets/fonts/KawitFreeCndItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maragsa';
    src: url('./assets/fonts/Maragsa-Display.woff2') format('woff2'),
        url('./assets/fonts/Maragsa-Display.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quiapo';
    src: url('./assets/fonts/Quiapo_Free.woff2') format('woff2'),
        url('./assets/fonts/Quiapo_Free.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.logo-glow {
  moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
